<template>
    <div :class="['select', 'select-selected']" @keyup.esc="showDropdown">
        <div :class="['field', getColorClass(selectedValue)]" @click="showDropdown" @keyup.enter="showDropdown"  @keyup.space="showDropdown" tabindex="0">
            <span>{{selectedValue}}</span>
            <span class="material-icons" aria-hidden="true">{{ !this.showOptions ? 'expand_more' : 'expand_less' }}</span>
        </div>
        <div v-if="showOptions" class="options"> 
            <div v-for="(value, index) in values" :key="index" >
                <div @click="chooseValue(value)" @keyup.enter="chooseValue(value)" @keyup.space="chooseValue(value)" tabindex="0"><h5 class="options--item">{{ value.label }}</h5></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { createUniqueId, on, off, elementContains } from '@/utils/wb-helper';
    import { shortenString, isset } from '@/utils/helpers';
    export default {
        name: 'MainCategorySelect',
        props: {
            name: {
                type: String,
                required: true,
            },
            fieldTypeId: {
                default: null,
            },
            values: {
                type: Array,
                required: true,
            },
            selectedFilters: {
                type: Map,
                default: null,
            }
        },
        data() {
            return {
                selectedValue: null,
                id: createUniqueId(),
            }
        },
        watch: {
            name(newValue){
                this.prepFilter();
            }
        },
        created() {
            for (let index = 0; index < this.values.length; index++) {
                if (this.values[index].selected) {
                    this.selected = this.values[index].label;
                }
            }
            this.prepFilter();
        },
        computed: {
            isOpened() {
                return this.$store.state.isOpened;
            },
            showOptions: function () {
                return this.id === this.$store.state.optionId;
            },
        },
        methods: {
            shortenString,
            prepFilter(){
                /*trying to set preset values*/
                if(isset(this.selectedFilters)){
                    var res = this.selectedFilters.get(this.fieldTypeId);
                    if(isset(res)){
                        this.selectedValue = res[0];
                    }
                }
            },
            chooseValue: function(value) {
                this.$emit('on-select', this.fieldTypeId, [value.label]);
                this.selectedValue = value.label;
                this.hideOption();
            },
            showDropdown: function() {
                if (!this.showOptions) {
                    this.$store.state.optionId = this.id;
                    this.addEvents();
                } else {
                    this.hideOption();
                }
            },
            hideOption: function() {
                this.$store.state.optionId = null;
                this.removeEvents();
            },
            getColorClass(label){
                switch(label){
                    case "Freizeit":
                    return "bg-blue";
                    break;
                    case "Ausbildung & Weiterbildung":
                    return "bg-jade";
                    break;
                    case "Beratung & Hilfe":
                    return "bg-meganta";
                    break;
                }
                return "";
            },
            addEvents: function() {
            // on(this.$el, 'click', this.onClick);
            on(document, 'click', this.onDocumentClick);
        },
        removeEvents: function() {
            // off(this.$el, 'click', this.onClick);
            off(document, 'click', this.onDocumentClick);
        },
        onClick: function(e) {
            e.stopPropagation();
        },
        onDocumentClick: function(e) {
            if (elementContains(this.$el, e.target)) return;

            this.hideOption();
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';
    @import '@/scss/_mixins.scss';

    .select{
        position: relative;
        display: flex;
        flex-direction: column;

        .label{
            color: #686B6F;
            font-weight: 400;
            margin-left: 5px;
            margin-bottom: 5px;
        }

        .field {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 42px;
            width: 100%;
            padding: 0.375rem 1rem;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            background-clip: padding-box;
            background: #fff;
            border-radius: 5px;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            border: 1px solid rgba(58, 58, 58, 0.1);
            color: #686B6F;
            cursor: pointer;
        }

        &-selected {
            .field {
                background: $primary;
                color: $white-color;
                font-weight: 600;

                &.purple {
                    background: $wb-purple;
                }

                &.orange {
                    background: $wb-orange;
                }

                &.meganta {
                    background: $wb-meganta;
                }

                &.jade {
                    background: $wb-jade;
                }

                &.primary {
                    background: $primary;
                }
            }
        }

        &-multiple {
            .field {
                min-height: 42px;
                height: auto;

                .chip-list {
                    margin-left: -10px;
                    &--item {
                        display: inline-block;
                        background: $primary;
                        color: $white-color;
                        padding: 3px 12px;
                        margin-right: 2px;
                        margin-bottom: 2px;
                        border-radius: 50px;
                        font-size: 13px;
                        text-align: center;
                        word-break: break-word;
                    }
                }
            }
        }

        .options {
            width: 100%;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 42px;
            z-index: 9999;
            background: #fff;
            box-shadow: 2px 4px 6px 0px #00000040;
            max-height: 320px;
            overflow-y: auto;

            h5 {
                font-weight: 400;
                font-size: 14px;
            }

            .form-check-label{
                width:100%;
            }

            &--item {
                width: 100%;
                margin-right: 0px;
                cursor: default;
                padding: 15px 1rem;

                .form-check {
                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;
                }

                .more {
                    margin-left: 40px;
                    .form-check-inline {
                        align-items: flex-start;
                        display: flex;
                        flex-direction: column;

                        &:first-child{
                            margin-top: 25px;
                        }
                    }
                }

                &:hover {
                    background: rgba(58, 58, 58, 0.15);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    body[dir="rtl"] {
        .select {
            .options {
                &--item {
                    .more {
                        margin-left: 0;
                        margin-right: 40px;
                    }
                }
            }
        }
    }

</style>